import { configHeaders } from "utils/request-config"

export interface Payment {
    username: string
    message : string
    amount  : number
    currency: string

}

const connectApi = (user) => {
    const headers = configHeaders(process.env.REACT_APP_CONNECT_API_SECRET_KEY || "")
    const api     = (
        process.env.REACT_APP_CONNECT_API_PAYMENT_URL + '/' +
        process.env.REACT_APP_CONNECT_API_VERSION
    )

    const createToken = async (payment: Payment) => {
        return await fetch(`${api}/token/create`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                username     : payment.username,
                message      : payment.message,
                amount       : payment.amount,
                currency     : payment.currency,
                full_name    : `${user?.firstName} ${user?.lastName}`,
                email        : user?.email,
                mobile_number: JSON.stringify(user?.mobile_number || {}),
            })
        }).then(
            res => res.json()
        )
    }

    const getToken = async (token: string, callback: any) => {
        await new Promise(r => setTimeout(r, 1000));
        fetch(
            `${api}/token/get/${token}`, {headers}
        ).then(
            res => res.json()
        ).then(
            json => callback && callback(json)
        )
    }

    return {createToken, getToken}
}

export default connectApi