import moment from "moment";
import { useState } from "react";

const APP_ID      = process.env.REACT_APP_FACEBOOK_APP_ID || '';
const API_VERSION = process.env.REACT_APP_FACEBOOK_APP_VERSION || 'v18.0';

const useFacebookLogin = (
    app_id      = APP_ID,
    api_version = API_VERSION
) => {
    const [response, setResponse] = useState<any>();

    const call = (action) => {
        let scope = "user_birthday,user_link,public_profile,instagram_basic,pages_read_engagement";
        if (window.FB) {
            window.FB.init({
                appId           : app_id,
                version         : api_version,
            })
            window.FB.login(action, {scope})
        }
    }

    const getBasicInfo = () => {
        let fields = "id,first_name,last_name,birthday,picture,link";
        call(() => {
            window.FB.api('/me', 'get', {fields}, (response: any) =>
                setResponse({
                    firstName         : response.first_name,
                    lastName          : response.last_name,
                    birthdate         : response.birthday && moment(response.birthday).format('YYYY-MM-DD') || '',
                    profile_photo     : response.picture?.data?.url,
                    social_media_links: {facebook: response?.link?.replace('https://www.facebook.com/', '')}
                })
            );
        })
    }

    const getConnectedAccounts = (callback) => {
        call(response => {
            let {access_token, ..._} = response;
            window.FB.api('/me/accounts', 'get', {access_token}, callback);
        })
    }

    const getConnectedInstagram = (callback) => {
        let fields = 'connected_instagram_account';
        getConnectedAccounts(response => {
            let page_id = response.data[0].id;
            window.FB.api(`/${page_id}`, 'get', {fields}, callback)
        })
    }

    const getInstagramInfo = () => {
        let fields = 'name,profile_picture_url,username,website,biography';
        getConnectedInstagram(response => {
            let id = response.connected_instagram_account.id;
            window.FB.api(`/${id}`, 'get', {fields}, (response: any) => {
                let name = response.name?.split(' ') || [''];
                setResponse({
                    lastName          : name.pop(),
                    firstName         : name.join(' '),
                    description       : response.biography,
                    profile_photo     : response.profile_picture_url,
                    username          : response.username,
                    social_media_links: {
                        instagram: `${response.username || ''}`,
                        website  : (response.website || '')
                            .replaceAll('http://')
                            .replaceAll('https://', '')
                    }
                })
            })
        })
    }

    return {getBasicInfo, getInstagramInfo, response}
}

export default useFacebookLogin;