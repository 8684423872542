import { selectUserInfo } from "app/common/auth/selectors";
import InputField from "app/components/InputField";
import { SocialMediaLinks } from "app/containers/Onboarding/types";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalize } from "utils";
import { social_medias } from "utils/options";
import Section from "./Section";

interface Form {
    social_media_links: SocialMediaLinks
}

interface Props {
    onChange: (form: Form) => void
}

const SocialMedia = memo(({onChange}: Props) => {
    const user = useSelector(selectUserInfo)

    const social_media_error = "At least one social media account is required"
    const [socmedError ,setSocmedError] = useState(false);
    const [social_media_links, setSocialMediaLinks] = useState(user?.social_media_links || {
        facebook : '',
        twitter  : '',
        instagram: '',
        linkedin : '',
        website  : '',
    });

    const setSocialMedia = (key, value) => {
        const socmed = {...social_media_links};
        socmed[key]  = value;
        setSocialMediaLinks(socmed);
        setSocmedError(
            !socmed.facebook &&
            !socmed.twitter &&
            !socmed.instagram &&
            !socmed.linkedin &&
            !socmed.website
        )
    }

    useEffect(() => onChange({social_media_links}), [social_media_links])

    return <Section title="SOCIAL MEDIA">
        {Object.keys(social_medias).map(social =>
            <InputField
                type          = 'text'
                label         = {capitalize(social)}
                name          = {social}
                placeholder   = 'username'
                max           = {30}
                error         = {socmedError}
                error_message = {socmedError ? social_media_error : ''}
                value         = {social_media_links[social]}
                style         = {{
                    inputContainer    : `icon social icon-${social}`,
                    container: social_media_links[social]? 'valid': ''
                }}
                onChange = {e => setSocialMedia(social, e.target.value)}
            />
        )}
        <InputField readonly
            type        = "text"
            name        = "payment_address_2"
            label       = "JustPayto"
            placeholder = "username"
            value       = {user?.preferred_username || user?.username}
            valid       = {!!(user?.preferred_username || user?.username)}
            style       = {{inputContainer: 'icon social icon-jpt'}}
        />
        <InputField
            type          = 'text'
            label         = 'Website'
            name          = 'website'
            placeholder   = 'www.yourwebsite.com'
            error         = {socmedError}
            error_message = {socmedError ? social_media_error : ''}
            value         = {social_media_links.website}
            style         = {{
                inputContainer: `icon social icon-link`
            }}
            onChange = {e => setSocialMedia('website', e.target.value)}
        />
    </Section>
})

export default SocialMedia