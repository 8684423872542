import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { selectUserInfo } from "app/common/auth/selectors";
import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import Modal, { DefaultModalProps } from "app/components/Modal";
import LoadingModal from "app/components/Modal/Loading";
import React, { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import connectApi from "utils/connect-api";
import { userDashboardPageActions } from "../../slice";

const PremiumPaymentMethodModal = memo(({state}: DefaultModalProps) => {
    const dispatch = useDispatch();
    const user     = useSelector(selectUserInfo);
    const classes  = useStyles();
    const {createToken, getToken} = connectApi(user)

    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.AUTO_DEDUCT);
    const [loading, setLoading]             = useState<boolean>()
    const [token, setToken]                 = useState("");
    const [paymentInfo, setPaymentInfo]     = useState<any>();
    const [loadingModal, setLoadingModal]   = useState(false)
    const [scriptSrc, setScriptSrc]         = useState(<></>)

    useEffect(() => {setScriptSrc(<></>)}, [state[0]])

    const onClick = () => {
        dispatch(userDashboardPageActions.updateActionTime())
        setLoading(true)
        if (paymentMethod == PaymentMethod.PAY_NOW) {
            // if (!token) {
            //     generateToken()
            // } else {
                openPaymentModal()
            // }
        } else {
            dispatch(userDashboardPageActions.changePlan(paymentMethod))
        }
    }

    const generateToken = async () => {
        const result = await createToken({
            username: Payment.username,
            message : Payment.message,
            amount  : Payment.amount + (user?.premium_balance || 0),
            currency: Payment.currency
        })
        setToken(result.token)
    }

    // Open Payment Modal after getting the token
    useEffect(() => {
        setPaymentInfo(undefined)
        if (token) {
            openPaymentModal()
        }
    }, [token])

    const openPaymentModal = async () => {
        // Initialize Payment Modal
        setScriptSrc(
            <Helmet>
                <script src="https://api-dev.justpayto.ph/connect/api/v3/payment-button.js"></script>
            </Helmet>
        )
        // Wait for the Payment Button to load
        await new Promise(r => setTimeout(r, 3000));
        // Set loading and modal states
        setLoading(false)
        state[1](false)
        // Start getting the Payment Status
        dispatch(userDashboardPageActions.updateActionTime())
        getToken(token, paymentInfoCallback)
        // Open Payment Modal
        document.getElementById(Payment.button_id)?.click()
    }

    // Update Payment Info for Payment Status checking
    const paymentInfoCallback = json => setPaymentInfo(json['payment_info'])

    // Check if Payment Status is success or "used"
    useEffect(() => {
        if (paymentInfo && paymentInfo.status == 'used') {
            setToken("")
            // Remove Payment Modal
            document.getElementById(`jpt-${Payment.username}`)?.remove()
            // Destroy Payment Button
            document.getElementById(Payment.button_id) && JPTButton.destroy()
            setLoadingModal(true)
            setPaymentInfo(undefined)
            dispatch(userDashboardPageActions.changePlan(paymentMethod))
        } else if (paymentInfo) {
            setPaymentInfo(undefined)
            dispatch(userDashboardPageActions.updateActionTime())
            getToken(token, paymentInfoCallback)
        }
    }, [paymentInfo])

    useEffect(() => {
        setLoading(false)
        setLoadingModal(false)
    }, [user?.groups])

    return <>
        <LoadingModal state={[loadingModal, setLoadingModal]}/>
        <Modal state={state} dialogClassname={classes.modal}>
            {scriptSrc}
            <div className={classes.content}>
                <h2 className={classes.title}>Choose how to pay<br/>for your Premium Account</h2>
                <div className="pseudo-form">
                    {/* Auto-deduct */}
                    <InputField
                        title   = {AutoDeduct.title}
                        type    = "radio"
                        name    = "payment_method"
                        checked = {paymentMethod == PaymentMethod.AUTO_DEDUCT}
                        onChange = {() => setPaymentMethod(PaymentMethod.AUTO_DEDUCT)}
                    />
                    <p>{AutoDeduct.content}</p>
                    {/* Pay now */}
                    <InputField
                        title   = {PayNow.title}
                        type    = "radio"
                        name    = "payment_method"
                        checked = {paymentMethod == PaymentMethod.PAY_NOW}
                        onChange = {() => setPaymentMethod(PaymentMethod.PAY_NOW)}
                    />
                    <p>{PayNow.content}</p>
                </div>
                {/* Hidden Payment Button */}
                <div
                    style     = {{display: "none"}}
                    className = {Payment.button_className}
                    username  = {Payment.username}
                    env       = {Payment.env}
                    // token     = {token}
                ></div>
                {/* Shown Button */}
                <ButtonLoader hasLoader loading={loading} text="Continue" style="filled blue full-width" onClick={onClick}/>
            </div>
        </Modal>
    </>
})

class Payment {
    static env              = process.env.REACT_APP_ENV
    static username         = process.env.REACT_APP_ENV != "prod"? "justpaytoph": "justpaytoph"
    static message          = "Premium Account Upgrade (30 days)"
    static amount           = 1000
    static currency         = "PHP"
    static button_id        = `jpt-${Payment.username}-button`
    static button_className = "jpt-button"
}

class PaymentMethod {
    static AUTO_DEDUCT = "auto_deduct"
    static PAY_NOW     = "pay_now"
}

class AutoDeduct {
    static title   = "Deduct from my transactions"
    static content = "Pay when you get paid, ₱ 1,000.00 will be deducted to your settlement amount/s. This way you'll automatically pay the Premium Account subscription when you receive the money."
}

class PayNow {
    static title   = "Pay now"
    static content = "Pay now with your credit card, debit card, bank account, or e-wallet."
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        modal: {
            borderRadius: "5px!important",
            fontFamily: "Arial",
            letterSpacing: "-0.2px",
            ["& > .modal"]: {
                maxWidth: "450px",
                padding: "40px 40px",
            }
        },
        title: {
            textAlign : "center",
            fontSize  : "24px",
            lineHeight: "2rem",
        },
        content: {
            ['& .pseudo-form']: {
                padding: "0 25px",
                margin: "10px",
                ['& > p']: {
                    textAlign: "left",
                    padding: "0 13px",
                    margin: "20px 0",
                    marginRight: "0",
                    lineHeight: "1rem",
                }
            },
            ['& .input-box']: {
                marginLeft: "-25px",
                marginBottom: "0",
                marginTop: "36px"
            },
            ['& .input-box label']: {
                fontSize: "18px",
                marginLeft: "10px"
            }
        }
    })
)

export default PremiumPaymentMethodModal