import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectForgotPasswordPage } from "./selectors";
import { ForgotPasswordPageActions, sliceKey as key, reducer } from "./slice";
import saga from "./saga";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { get_ip, validateEmail } from "utils";
import {partners} from 'app/containers/HomePage';

// Components
import MasterPage   from "../../MasterPage";
import ButtonLoader from "app/components/ButtonLoader";
import InputField   from "app/components/InputField";
import ErrorModal   from "app/components/Modal/ErrorModal";

// Assets
import bg   from 'assets/img/background/justpayto-philippines-payments-3.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';
import success_icon from 'assets/img/success.png';

// Styles
import 'styles/sass/login.scss';
import 'styles/sass/forgot-password.scss';

const ForgotPassword = memo(() => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const dispatch            = useDispatch();
    const forgotPasswordState = useSelector(selectForgotPasswordPage);

    const [email, setEmail]           = useState('');
    const [isEmpty, setEmptyEmail]    = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const errorModalState             = useState(false);
    const [success, setSuccess]       = useState(false);

    const Partners = () => (
        <section className="partners">
        {
            partners.map(partner =>
                <a href={`/${partner.slug}`} title={partner.name} target="_blank" rel="noreferrer">
                    <figure className="avatar">
                        <img src={partner.logo_url} alt={partner.name} /> 
                    </figure>
                </a>
            )
        }
        </section>
    )

    const submitRequest = async () => {
        setSubmitting(true);
        let ip = await get_ip();
        dispatch(ForgotPasswordPageActions.submitRequest({email, ip}));
    }

    useEffect(() => {
        errorModalState[1](forgotPasswordState.result !== undefined && !forgotPasswordState.result);
        setSuccess(!!forgotPasswordState.result);
        setSubmitting(false);
    }, [forgotPasswordState.result, forgotPasswordState.error])

    return (<>
        <ErrorModal state = {errorModalState} message = {forgotPasswordState.error || ''} onClose = {() => dispatch(ForgotPasswordPageActions.clearError())}/>
        <MasterPage hasHeader
            title = "Forgot Password"
            backgroundImage = {bg}
        >
            <section className="action-box-wrapper forgot-password">
            {!success?
                    <section className="login action-box content-box small">
                        <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
                        <div jpt-form="login" className="pseudo-form">
                            <h1>Forgot Password</h1>
                            <h3>Instructions will be sent to your <br/>Email Address.</h3>
                            <InputField loader
                                error       = {isEmpty || !validEmail}
                                type        = 'email'
                                name        = 'email'
                                placeholder = 'Your Email'
                                style       = {{inputContainer: 'icon icon-email'}}
                                value       = {email}
                                onChange    = {e => {
                                    setEmptyEmail(!e.target.value)
                                    setEmail(e.target.value)
                                }}
                            />

                            <div className="error-container" data-error-container>
                                {isEmpty && 'Email is required'}
                                {!isEmpty && !validEmail && 'Email is not valid'}
                            </div>
                            <section className="footer">
                                <ButtonLoader hasLoader
                                    loading = {submitting}
                                    name    = 'send'
                                    style   = 'filled blue'
                                    text    = 'Send'
                                    onClick = {() => {
                                        setEmptyEmail(!email);
                                        if (!email) return;
                                        setValidEmail(true);
                                        let valid = validateEmail(email);
                                        setValidEmail(valid);
                                        if (valid) submitRequest();
                                    }}
                                />
                                <ButtonLoader route = "/login" style="text blue" text="Back"/>
                            </section>
                        </div>
                </section>: <>
                    <section className="action-box content-box small success">
                        <img className="logo" src={success_icon} alt="Success Icon"/>
                        <h1 className="center">Success</h1>
                        <p className="center">A password reset link was sent to your email</p>
                        <section className="social-links">
                            <ButtonLoader
                                route  = "https://www.facebook.com/JustPayto-PH-2289070334640914/"
                                target = "_blank"
                                rel    = "noreferrer"
                                name   = "jpt_fb"
                                style  = "filled icon social icon-facebook"
                            />
                            <ButtonLoader
                                route  = "https://www.twitter.com/justpayto"
                                target = "_blank"
                                rel    = "noreferrer"
                                name   = "jpt_twitter"
                                style  = "filled icon social icon-twitter"
                            />
                            <ButtonLoader
                                route  = "https://www.instagram.com/justpayto"
                                target = "_blank"
                                rel    = "noreferrer"
                                name   = "jpt_instagram"
                                style  = "filled icon social icon-instagram"
                            />
                        </section>
                        <section className="divider"><span>OR</span></section>
                        <h3>Start Paying Bills</h3>
                        <Partners/>
                    </section>
                </>
            }
            </section>
        </MasterPage>
    </>)
})

export default ForgotPassword;