import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// Non - Package Imports
import { tryInitPageable } from './pageable';

// Load Images
import logo             from 'assets/logo.svg';
import paymentpage      from 'assets/img/landing-page/autosweep-rfid-paymentpage.png';
import paymentsolutions from 'assets/img/landing-page/justpayto-philippines-paymentsolutions.png';
import bspregulated     from 'assets/img/landing-page/justpayto-philippines-bspregulated.png';
import amlc             from 'assets/img/landing-page/justpayto-philippines-amlc.png';
import pcidsscertified  from 'assets/img/landing-page/justpayto-philippines-pcidsscertified.png';

// Load Stylings
import 'styles/sass/landing-page.scss';

// Components
import AnnouncementModal from 'app/components/Modal/AnnouncementModal';
import EarlyAccessModal from 'app/components/Modal/EarlyAccessModal';
import LogInModal       from 'app/components/Modal/LogInModal';

// React-Redux
import { landingPageActions, reducer, sliceKey } from './slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { landingPageSaga } from './saga';
import TwoFAModal from 'app/components/Modal/TwoFAModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLandingPage } from './selectors';
import { ANNOUNCEMENT } from 'app/common/core_api/resources';

const AutoPopupModals = {
    'early-access': false,
    'log-in'      : false,
}

export const partners = [
    {
        'name'      : 'Bayad Center',
        'slug'      : 'bayadcenter',
        'logo_url'  : 'https://d3fqsxyabmujkl.cloudfront.net/partners/bayadcenter/logo/1550299721.png',
        'status'    : 'on',
    },{
        'name'      : 'Autosweep RFID',
        'slug'      : 'autosweeprfid',
        'logo_url'  : 'https://d3fqsxyabmujkl.cloudfront.net/partners/1551348821.jpg',
        'status'    : 'on',
    },{
        'name'      : 'Beep Card',
        'slug'      : 'beep',
        'logo_url'  : 'https://d3fqsxyabmujkl.cloudfront.net/partners/1562910520.png',
        'status'    : 'on',
    },{
        'name'      : 'Albay Power and Energy Corp.',
        'slug'      : 'apec',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574304467LAjk.',
        'status'    : 'on',
    },{
        'name'      : 'BATELEC II',
        'slug'      : 'batelec',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1550740748gWLa.png',
        'status'    : 'on',
    },{
        'name'      : 'Davao Light and Power Co., Inc.',
        'slug'      : 'davaolight',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574909848zQSc.png',
        'status'    : 'on',
    },{
        'name'      : 'Meralco Bills Payment',
        'slug'      : 'meralco',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1550740845OYKD.png',
        'status'    : 'on',
    },{
        'name'      : 'Meralco Kuryente Load',
        'slug'      : 'kload',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574813893hMH0.png',
        'status'    : 'on',
    },{
        'name'      : 'Visayan Electric Company',
        'slug'      : 'veco',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574239534DTmu.',
        'status'    : 'on',
    },{
        'name'      : 'RCBC Bankard',
        'slug'      : 'rcbcbankard',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574305403prDf.',
        'status'    : 'on',
    },{
        'name'      : 'Cignal TV',
        'slug'      : 'cignal',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574820617MWL7.',
        'status'    : 'on',
    },{
        'name'      : 'Cablelink TV',
        'slug'      : 'cablelink',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1550739358Lvmw.png',
        'status'    : 'on',
    },{
        'name'      : 'Sky Cable/Destiny Cable',
        'slug'      : 'skycable',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1568092484xwd7.jpg',
        'status'    : 'on',
    },{
        'name'      : 'Sky Affiliates',
        'slug'      : 'skyaffiliates',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574403297stew.png',
        'status'    : 'on',
    },{
        'name'      : 'Dragonpay',
        'slug'      : 'dragonpay',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/15743050893aNw.',
        'status'    : 'on',
    },{
        'name'      : 'Home Credit',
        'slug'      : 'homecredit',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1575354821Y2SR.png',
        'status'    : 'on',
    },{
        'name'      : 'Globe Telecom',
        'slug'      : 'globe',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/15744242787fHA.png',
        'status'    : 'on',
    },{
        'name'      : 'PLDT',
        'slug'      : 'pldt',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/15507402613FHt.png',
        'status'    : 'on',
    },{
        'name'      : 'Smart Communications',
        'slug'      : 'smart',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1550740294NKRm.png',
        'status'    : 'on',
    },{
        'name'      : 'Legazpi City Water District',
        'slug'      : 'lcwd',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574300851eVq0.png',
        'status'    : 'on',
    },{
        'name'      : 'Manila Water Company',
        'slug'      : 'manilawater',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/157429925299Em.',
        'status'    : 'on',
    },{
        'name'      : 'Maynilad Water',
        'slug'      : 'maynilad',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/15507419947XSl.png',
        'status'    : 'on',
    },{
        'name'      : 'POEA',
        'slug'      : 'poea',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574298835Yy15.png',
        'status'    : 'on',
    },{
        'name'      : 'Pag-IBIG Fund',
        'slug'      : 'pagibig',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574314351BTs7.',
        'status'    : 'on',
    },{
        'name'      : 'Cebu Pacific Air',
        'slug'      : 'cebupacificair',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1575424235JlYj.png',
        'status'    : 'on',
    },{
        'name'      : 'PhilHealth',
        'slug'      : 'philhealth',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574302411lZqx.',
        'status'    : 'on',
    },{
        'name'      : 'STI College',
        'slug'      : 'sti',
        'logo_url'  : 'https://d9t5qjot8jvsq.cloudfront.net/client-assets/bayadcenter/biller-assets/1574313243Y0kX.png',
        'status'    : 'on',
    }
];

const HomePage = memo(() => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: landingPageSaga });
    const dispatch               = useDispatch();
    const setPageable            = useState()[1];
    const twoFAModalState        = useState(false);
    const earlyAccessModalState  = useState(AutoPopupModals['early-access']);
    const logInModalState        = useState(AutoPopupModals['log-in']);
    const landingPage            = useSelector(selectLandingPage);
    const announcementModalState = useState(!!landingPage.announcement);

    useEffect(() => {dispatch(landingPageActions.getAnnouncement())}, [])
    useEffect(() => {
        window.addEventListener("resize", tryInitPageable);
        setPageable(tryInitPageable());
    }, [setPageable])

    return (
        <>
            <AnnouncementModal src={ANNOUNCEMENT} modalState={announcementModalState} />
            <Helmet>
                <title>Log In | { process.env.REACT_APP_NAME }</title>
            </Helmet>
            <main id="main">
            <section data-anchor="intro" className="section intro">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <img src={logo} alt="JustPayto" className="intro__logo"/>
                        <h1>A better way to send and receive money.</h1>
                        <p>Send money and make payments using your credit card, your bank account, over-the-counter, or e-money.</p>
                        <p>Accept payments online, in person or in your store, by email or mobile phone.</p>
                        <p>Receive money directly where you want it - any bank or e-wallet, even cash pickup or delivery.</p>
                        <p>
                            <a href="#early-access" className="early-access" onClick={() => earlyAccessModalState[1](true)}>Get Early Access</a>
                            <EarlyAccessModal state={earlyAccessModalState}/>
                            {' | '}
                            <a href="#log-in" onClick={() => logInModalState[1](true)} className="log-in black">Log In</a>
                            <LogInModal state = {logInModalState} twoFAModalState = {twoFAModalState} />
                            <TwoFAModal state = {twoFAModalState} />
                        </p>
                    </section>
                </section>
                <section className="section__pane graphic">
                    <img src={paymentpage} alt="Payment Page" />
                </section>
            </section>
            <section data-anchor="about" className="section about">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <h1>Payments should be easy. Simple. Convenient.</h1>
                        <p>Avoid the hassle of using complicated apps and working with multiple banks. No need to convert your cash to e-money or e-load or e-anything.</p>
                        <p>Easily pay for goods or services. Conveniently send money to friends or family. Anywhere in the world.</p>
                        <p>Get paid instantly by customers, receive money from friends and family, through Facebook, Instagram, SMS or email.</p>
                        <p>All you need is a Payment Address and a Payment Page. Yes, it's more than just a link.</p>
                        <p><a href="#early-access" className="early-access" onClick={() => earlyAccessModalState[1](true)}>Create your account. Get early access.</a></p>
                    </section>
                </section>
                <section className="section__pane graphic">
                    <img src={paymentsolutions} alt="Payment Solutions" />
                </section>
            </section>
            <section data-anchor="partners" className="section partners">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <h1>Bills payments.<br/>Reloading.<br/>Top up.</h1>
                        <p>Never stand in line again. Pay your bills anytime, anywhere, using any device.</p>
                        <p>Use any card with a Visa or MasterCard logo, use your bank account, pay over-the-counter at the most convenient establishment, even use any kind of e-money.</p>
                        <p>Try it! Choose a biller to start paying bills now.</p>
                        <p><a href="#billers" className="black">See all billers</a></p>
                        <section className="partners__icons">
                            {
                                partners.map(partner =>
                                    partner.status === 'on' &&
                                    <a href={`/${partner.slug}`} title={partner.name} target="_blank" rel="noreferrer">
                                        <figure className="avatar">
                                            <img src={partner.logo_url} alt={partner.name} /> 
                                        </figure>
                                    </a>
                                )
                            }
                        </section>
                    </section>
                </section>
                <section className="section__pane graphic">
                </section>
            </section>
            <section data-anchor="business" className="section business">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <h1>For you.<br/>For your business.<br/>For everyone.</h1>
                        <p>Get remittances to their destination through our network of banks and partners. Pay local bills and reload accounts instantly from another country.</p>
                        <p>Accepting credit card payments is easy - no merchant account required, no device to setup, no app to install. Expand your reach and increase sales by accepting globally recognized payment options and local payment methods.</p>
                        <p>The easiest way for you to request for money and get paid from anyone around the world, anytime.</p>
                        <p><a href="#early-access" className="early-access" onClick={() => earlyAccessModalState[1](true)}>Sign up now, Get early access.</a></p>
                    </section>
                </section>
                <section className="section__pane graphic">
                </section>
            </section>
            <section data-anchor="security" className="section security">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <h1>Secure. Safe. Regulated.</h1>
                        <p>Every transaction is protected using SHA-2 and 2048-bit encryption, on AWS and Azure servers, along with the banks’ existing infrastructure, to guard against unauthorized transactions.</p>
                        <p>Our PCI-DSS Certification ensures that your transactions are safely transmitted, processed and stored in a secure environment.</p>
                        <p>Transactions are monitored and reported daily to the Anti-Money Laundering Council (AMLC).</p>
                        <p>JustPayto is licensed and regulated by the Bangko Sentral ng Pilipinas.</p>
                        <section className="security__certifications">
                            <img src={bspregulated} alt="BSP Logo" />
                            <img src={amlc} alt="AMLC Logo" />
                            <img src={pcidsscertified} alt="PCI Logo" />
                        </section>
                    </section>
                </section>
                <section className="section__pane graphic">
                </section>
            </section>
            <section data-anchor="contact" className="section contact">
                <section className="section__pane content">
                    <section className="content__wrapper">
                        <h1>Contact Us.</h1>
                        <p>
                            <strong>Customer Support</strong><br/>
                            Do you have a question about using JustPayto or a problem with your account?<br/>
                            <strong><em>hello@justpay.to | (632) 8636 7546</em></strong>
                        </p>
                        <p>
                            <strong>Business & Partnerships</strong><br/>
                            Are you interested in a payment solution or partnering with JustPayto?<br/>
                            <strong><em>hello@justpay.to | (632) 8636 7546</em></strong>
                        </p>
                        <p>
                            <strong>Press & Media</strong><br/>
                            Are you press and looking to do a story about JustPayto?<br/>
                            <strong><em>info@justpay.to | (632) 8636 7546</em></strong>
                        </p>
                        <section className="contact__social">
                            <a href="https://www.facebook.com/JustPayto-PH-2289070334640914/" target="_blank" rel="noreferrer"><section className="contact__social__item facebook"></section></a>
                            <a href="https://www.twitter.com/justpayto" target="_blank" rel="noreferrer"><section className="contact__social__item twitter"></section></a>
                            <a href="https://www.instagram.com/justpayto" target="_blank" rel="noreferrer"><section className="contact__social__item instagram"></section></a>
                            <a href="https://www.linkedin.com/company/justpayto" target="_blank" rel="noreferrer"><section className="contact__social__item linkedin"></section></a>
                        </section>
                    </section>
                </section>
                <section className="section__pane graphic">
                </section>
            </section>
            </main>
        </>
    );
})

export default HomePage;